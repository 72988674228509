import marketingMixin from './marketingMixin';

export default {
  mixins: [marketingMixin],
  data() {
    return {};
  },
  methods: {
    handleNewSession(data, redirectToDashboard) {
      this.$store.dispatch('setToken', data.data);
      window.localStorage.setItem('growlyticsToken', data.token);

      let systemId = data.data.userId;
      data.data.name = data.data.firstName + ' ' + data.data.lastName;
      this.marketingTrackEvent('Logged In', data.data);
      this.marketingIdentifyCustomer(systemId, data.data);

      // Check for account suspension warning.
      setTimeout(() => {
        this.$root.$emit('newSession', data.data);
      }, 1000);

      // Identify customer for marketing.
      if (redirectToDashboard) this.$router.push({ name: 'Dashboard', query: this.$route.query });
      else this.$router.push({ name: 'SelectCompany', query: this.$route.query });
    },

    getRegisterationForm() {
      return {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        recaptchaToken: ''
      };
    },

    getRegisterationFormRules() {
      return {
        first_name: [
          {
            required: true,
            message: 'First name is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'Minimum 3 characters required.',
            trigger: 'blur'
          },
          {
            max: 50,
            message: 'Only 50 characters allowed.',
            trigger: 'blur'
          }
        ],
        last_name: [
          {
            required: true,
            message: 'Last name is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'Minimum 3 characters required.',
            trigger: 'blur'
          },
          {
            max: 50,
            message: 'Only 50 characters allowed.',
            trigger: 'blur'
          }
        ],
        company: [
          {
            required: true,
            message: 'Company is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'Minimum 3 characters required.',
            trigger: 'blur'
          },
          {
            max: 50,
            message: 'Only 50 characters allowed.',
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            message: 'Mobile no is required.',
            trigger: 'blur'
          },
          {
            min: 10,
            max: 10,
            message: 'Must be of 10 digits.',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: 'Email is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'Minimum 3 characters required.',
            trigger: 'blur'
          },
          {
            max: 50,
            message: 'Only 50 characters allowed.',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: 'Password is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'Minimum 3 characters required.',
            trigger: 'blur'
          },
          {
            max: 50,
            message: 'Only 50 characters allowed.',
            trigger: 'blur'
          }
        ]
      };
    },

    getLoginForm() {
      return {
        email: '',
        password: ''
      };
    },

    getLoginFormRules() {
      return {
        first_name: [
          {
            required: true,
            message: 'First name is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 50,
            message: 'First name should be of 3 to 50 characters.',
            trigger: 'blur'
          }
        ],
        last_name: [
          {
            required: true,
            message: 'Last name is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 150,
            message: 'Last name should be 1 to 50 characters.',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: 'Email is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 150,
            message: 'Email can not exceed 150 characters.',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: 'Password is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 50,
            message: 'Password must be of 3 to 50 characters.',
            trigger: 'blur'
          }
        ]
      };
    }
  }
};
